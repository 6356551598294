<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="bsnDateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
        <report-view ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원권 변동 현황(개인/법인)</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onPrintButtonClicked"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                :data-source="content.membershipChangeStatus"
                v-bind="gridOptions"
                :aggregates="membershipChangeGridAggregates"
                @actionComplete="membershipChangesStatusGridActionComplete"
                @queryCellInfo="membershipChangesStatusGridACustomCell"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import ReportView from "../../components/common/report/ReportView.vue";
// import InputText from "@/components/common/text/InputText";
// import CustomShortcutButton from "@/components/button/CustomShortcutButton";
import ErpButton from "@/components/button/ErpButton.vue";

// import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Resize ,Group} from "@syncfusion/ej2-vue-grids";
import moment from "moment/moment";
import {numberWithCommas} from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
// import { orderBy as _orderBy, maxBy as _maxBy, minBy as _minBy, groupBy as _groupBy, } from "lodash";

export default {
  name: "MembershipChangeStatusIndividualCorporate",
  components: {
    EjsGridWrapper,
    InputDateRange,
    ErpButton,
    ReportView,

  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      searchOptions: {
        bsnDateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        bsnDateTo: moment().format('YYYY-MM-DD'),
      },
      content: {
          membershipChangeStatus: []
      },

    };
  },
  created() {},
  computed: {
    isPopupOpened() {
      return false;
    },
    bsnDateRange: {
      get() {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        allowResizing:true,
        allowGrouping:false,
        isSelectedRowRetain:true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            headerText: "명의변경(양도 - 양수)",
            columns: [
              {
                field: "renameMonth",
                headerText: "년월",
                textAlign: 'center',
                type: "date",
                width: 50,
                format: { type: "date", format: "yyyy-MM" },
              },
              {
                field: "fitToFit",
                headerText: "개인 - 개인",
                textAlign: 'right',
                type: "string",
                width: 100,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
              {
                field: "fitToCpr",
                headerText: "개인 - 법인",
                textAlign: 'right',
                type: "string",
                width: 100,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
              {
                field: "cprToCpr",
                headerText: "법인 - 법인",
                textAlign: 'right',
                type: "string",
                width: 100,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
              {
                field: "cprToFit",
                headerText: "법인 - 개인",
                textAlign: 'right',
                type: "string",
                width: 100,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
            ]
          }
          ] ,
        // groupSettings: {
        //     columns: ["fitToFit","fitToCpr","cprToCpr","cprToFit"],
        //     showDropArea: false,
        // },
      };
    },
    membershipChangeGridAggregates() {
        return [
            {
                columns: [
                    {
                        field: "renameMonth",
                        aggregationType: "TotalCaption",
                        customAggregate: "합계",
                    },
                    {
                        field: "fitToFit",
                        aggregationType: "TotalSum",
                    },
                    {
                        field: "fitToCpr",
                        aggregationType: "TotalSum",
                    },
                    {
                        field: "cprToCpr",
                        aggregationType: "TotalSum",
                    },
                    {
                        field: "cprToFit",
                        aggregationType: "TotalSum",
                    },
                ]
            }
        ];
    },
  },
  mounted() {},
  methods: {
    onViewButtonClicked() {
      this.fetchMembershipChangeStatusIndividualCorporate();
    },
    async fetchMembershipChangeStatusIndividualCorporate() {
        let params = {
            fromDate: this.searchOptions.bsnDateFrom,
            toDate: this.searchOptions.bsnDateTo
        };
        if (params.fromDate == undefined || params.toDate == undefined) {
          this.errorToast("기준일자를 입력해주세요");
        } else {
          let res = await GolfErpAPI.getMembershipChangeStatusIndividualCorporate(params);
          if (res) {
              this.content.membershipChangeStatus = res;
          }
        }
    },
    onPrintButtonClicked() {
        if (this.content.membershipChangeStatus.length === 0) {
            this.errorToast("조회된 데이터가 없습니다");
        } else {
            const result = this.$refs.grid.getGridBatchData();

            if (result.length < 1) {
                return this.errorToast(this.$t('report.popupMessage.noData'));
            }

            const searchOptionsList = [
                {
                    key: '조회일자',
                    value: `${this.searchOptions.bsnDateFrom} ~ ${this.searchOptions.bsnDateTo}`
                }
            ];

            const searchOptions = searchOptionsList
                .filter(item => !!item.value)
                .map(item => item.key + ': ' + item.value)
                .join(', ');

            const reportData = {
                reportJson: {
                    jsonData: result,
                    username: this.userName,
                    uniqueCode: this.$options.name,
                    searchOptions,
                },
            };
            this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
        }
    },
    onExcelButtonClicked() {
        if (this.content.membershipChangeStatus.length === 0) {
            this.errorToast("조회된 데이터가 없습니다");
        } else {
            this.$refs.grid.excelExport({fileName:"회원권 변동 현황(개인법인).xlsx"});
        }
    },
    membershipChangesStatusGridActionComplete() {
        this.count = numberWithCommas(
            this.$refs.grid?.getGridBatchCount() || 0
        );
    },
    membershipChangesStatusGridACustomCell(args){
      if ([
        "fitToFit", "fitToCpr", "cprToCpr", "cprToFit"
      ].includes(args.column.field) && args.data[args.column.field] === 0) {
        args.cell.innerText = "-";
      }
    },
  },
};
</script>
